import * as THREE from "three"
import { Seed } from "types/Seed"
import { CylinderTiles } from "./CylinderTiles"
// @ts-ignore
import basicVS from "../Shaders/basic.vert.glsl"
// @ts-ignore
import seedingPointerFS from "../Shaders/seeding-pointer.frag.glsl"
import { Colors } from "Theme/Colors"
import { clamp } from "three/src/math/MathUtils"


const rayCaster = new THREE.Raycaster()

/**
 * The SeedsManager is responsible for storing the seeds data, and display the seeds in the 3D space.
 * It can receive array of seeds to display and is responsible for adding those to the scene.
 */
export class SeedsManager {
	scene: THREE.Scene
	camera: THREE.Camera
	seeds: Record<string, { seed: Seed, mesh: THREE.Mesh }>
	meshes: THREE.Mesh[]
	cylinderTiles: CylinderTiles
	radius: number
	visible: boolean

	constructor(scene: THREE.Scene, camera: THREE.Camera, cylinderTiles: CylinderTiles) {
		this.seeds = {}
		this.meshes = []
		this.scene = scene
		this.camera = camera
		this.cylinderTiles = cylinderTiles
		this.radius = clamp(1300 / window.innerWidth, 0.5, 1.3)
		this.visible = true
	}

	addSeeds(seeds: Seed[]) {
		// we go through all the seeds received and add those we didn't add previously
		for (const seed of seeds) {
			if (!this.seeds[seed.id]) {
				// add the seed to the scene
				this.addSeed(seed)
			}
		}
	}

	/**
	 * Adds a seed to the scene and saves the seed data in the memory for a future usage
	 * @param seed the seed to add to the scene
	 */
	private addSeed(seed: Seed) {
		// get the world 3D coordinates of the seed
		const coord3d = this.cylinderTiles.tile2dToWorldCoordinates(seed.positionX, seed.positionY)

		// the seed needs to be offseted a little bit
		const xz = new THREE.Vector2(coord3d.x, coord3d.z)
		const d = xz.length()
		const a = xz.angle()
		const pos = new THREE.Vector3(
			Math.cos(a) * (d - 0.5),
			coord3d.y,
			Math.sin(a) * (d - 0.5)
		)

		// create the 3D mesh
		const radius = this.radius * (seed.author === "system" ? 0.5 : 2)
		const geometry = new THREE.CircleGeometry(radius, 32)
		const material = new THREE.ShaderMaterial({
			vertexShader: basicVS,
			fragmentShader: seedingPointerFS,
			side: THREE.DoubleSide,
			depthTest: false,
			depthWrite: false,
			transparent: true,
			uniforms: {
				uRadius: { value: radius },
				uColor: { value: Colors.primaryV3.clone() },
			}
		})
		const mesh = new THREE.Mesh(geometry, material)
		mesh.renderOrder = 999
		mesh.name = seed.id
		mesh.position.copy(pos)
		mesh.rotation.y = -a + Math.PI*.5
		mesh.visible = this.visible

		// add it to the scene and save the data in-memory
		this.scene.add(mesh)
		this.seeds[seed.id] = { seed, mesh }
		this.meshes.push(mesh)
	}

	getSeedFromMouse(x: number, y: number): Seed | null {
		const mouse = new THREE.Vector2(x, y)
		rayCaster.setFromCamera(mouse, this.camera)

		// calculates objects intersecting
		const intersects = rayCaster.intersectObjects(this.meshes)
		if (intersects.length > 0) {
			const intersect = intersects[0]
			return this.seeds[intersect.object.name].seed
		}

		// nothing has been reached
		return null
	}

	setSeedsVisible(visible: boolean) {
		this.visible = visible
		for (const mesh of this.meshes) {
			mesh.visible = visible
		}
	}

	hoverSeed(seed: Seed | null) {
		for (const mesh of this.meshes) {
			// @ts-ignore
			mesh.material.uniforms.uColor.value = Colors.primaryV3.clone()
		}
		
		if (seed) {
			// @ts-ignore
			this.seeds[seed.id].mesh.material.uniforms.uColor.value = Colors.errorV3.clone()
		}
	}
}