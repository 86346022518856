import styled, { css } from "styled-components"
import { Colors } from "Theme/Colors"


interface IProps {
	active: boolean
	icon: string
	activeIcon: string
	onToggle: (state: boolean) => void
	title?: string
}

interface IStProps {
	active: boolean
}

const StToolIcon = styled.button<IStProps>`
	background: ${Colors.primary};
	border: none;
	border-radius: 80px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 60px;
	width: 60px;
	cursor: pointer;
	transition: all 0.3s ease-out;
	color: white;
	
	i {
		font-size: 32px;
		transition: all 0.3s ease-out;
	}

	span {
		flex-grow: 2;
	}

	&:hover {
		transform: scale(1.05);
	}

	${props => props.active && css`
		background: ${Colors.error};
	`}

	@media (max-width: 768px) {
		height: 42px;
		width: 42px;

		i {
			font-size: 22px;
		}
	}
`

export function ToolIcon({
	active,
	icon,
	activeIcon,
	onToggle,
	title,
}: IProps) {
	return (
		<StToolIcon type="button" onClick={() => onToggle(!active)} active={active} title={title}>
			<i className={`fas fa-${active ? activeIcon : icon}`}/>
		</StToolIcon>
	)
}