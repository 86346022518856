import { Button } from "Components/Button"
import { forwardRef, MutableRefObject, useEffect, useRef, useState } from "react"
import { useRecoilValue } from "recoil"
import { touchModeState } from "State/TouchMode"
import { userSettingsState } from "State/UserSettings"
import styled from "styled-components"
import { Colors } from "Theme/Colors"
import { isNull } from "util"
import DawConfig from "../Config/daw"
import { CylinderTiles } from "../Logic/Objects/CylinderTiles"
import { SceneManager } from "../Logic/SceneManager"
import { toolState } from "../State/Tool"
import { Seed } from "../types/Seed"
import { MessageAddSeed } from "../types/SocketMessages"
import { ToolTypes } from "../types/Tools"
import { IVector2 } from "../Utils/Vector2d"


const StBtnCont = styled.div`
	position: absolute;
	top: calc(50% + 80px);
	left: 50%;
	transform: translateX(-50%);
`

interface Props {
	seedHovered: Seed | null
	onSeedHovered: (seed: Seed | null) =>void
	sceneManager: SceneManager | null
	onAddSeed: (x: number, y: number) => void
	addingSeedPosition?: IVector2 | null
	onSeedClicked: (seed: Seed) => void
}

export const Scene3d = forwardRef<HTMLCanvasElement, Props>(({ 
	seedHovered,
	onSeedHovered,
	onAddSeed,
	sceneManager,
	addingSeedPosition,
	onSeedClicked,
}, ref) => {
	// user setting state
	const userSettings = useRecoilValue(userSettingsState)
	// get the tool currently active
	const activeTool = useRecoilValue(toolState)
	const isToolSeeding = activeTool === ToolTypes.SEEDING

	// are we in touch mode ? 
	const isTouchModeState = useRecoilValue(touchModeState)

	// store mouse pos here
	const mousePos = useRef<IVector2>()
	// store touch pos here
	const touchPos = useRef<IVector2>()

	// use a ref because the function passed does not contain the right value
	const isToolSeedingRef = useRef<boolean>(isToolSeeding)

	// ref to the button to add the seed (for mobile only)
	const addSeedBtnRef = useRef<HTMLButtonElement>(null)


	// adds a seed in the environment at a given MOUSE position, if available
	const addSeed = (x: number, y: number) => {
		if (!sceneManager) return

		// is there an intersection with a Tile of the environment, if so which one
		const intersection = sceneManager.getCylinderTiles().getTilesCoordinatesFromMouse(x, y)
	
		if (intersection) {
			const canBePlaced = sceneManager.getCylinderTiles().canSeedBePlaced(intersection.coordinates)
			if (!canBePlaced) {
				console.error("CLIENT: seed cannot be placed here")
				return
			}
	
			onAddSeed(
				intersection.coordinates.x * DawConfig.TILE_SIZE + intersection.coordinates.dx,
				intersection.coordinates.y * DawConfig.TILE_SIZE + intersection.coordinates.dy,
			)
		}
	}

	const onCanvasClick = (evt: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
		if (!sceneManager || (isToolSeeding && isTouchModeState)) return

		if (isToolSeeding) {
			// get mouse position in screen normalized coordinates [-1; 1]
			const x = (evt.clientX / window.innerWidth) * 2 -1
			const y = - (evt.clientY / window.innerHeight) * 2 + 1
			addSeed(x, y)
		}
		// we are on regular navigation
		else {
			if (seedHovered) {
				evt.preventDefault()
				onSeedClicked(seedHovered)
			}
		}
	}

	const onMobileAddSeed = () => {
		addSeed(0, 0)
	}

	const checkIntersection = (checkSeedsIntersection: boolean = true) => {
		if (!sceneManager || !mousePos.current) return

		const { x, y } = mousePos.current

		if (isToolSeedingRef.current) {
			if (addingSeedPosition) return
	
			// is there an intersection with a Tile of the environment, if so which one
			const intersection = sceneManager.getCylinderTiles().getTilesCoordinatesFromMouse(x, y)
	
			if (intersection) {
				sceneManager.setSeedingVisible(true)
				sceneManager.seedingCursor.updatePosition(intersection.point)
	
				const canBePlaced = sceneManager.getCylinderTiles().canSeedBePlaced(intersection.coordinates)
				if (canBePlaced) {
					sceneManager.seedingCursor.updateColor(Colors.greenV3)
					// enable the button to add a seed
					if (addSeedBtnRef.current) {
						addSeedBtnRef.current.disabled = false
					}
				}
				else {
					sceneManager.seedingCursor.updateColor(Colors.redV3)
					// disable the button to add a seed
					if (addSeedBtnRef.current) {
						addSeedBtnRef.current.disabled = true
					}
				}
			}
			else {
				sceneManager.setSeedingVisible(false)
				// disable the button to add a seed
				if (addSeedBtnRef.current) {
					addSeedBtnRef.current.disabled = true
				}
			}
		}
		// we are not in seeding mode let's check for hovers on the seeds in the environment
		else {
			if (checkSeedsIntersection) {
				const intersection = sceneManager.getSeedsManager().getSeedFromMouse(x, y)
				if (intersection !== seedHovered) {
					onSeedHovered(intersection)
				}
			}
		}
	}

	const onMouseMove = (evt: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
		if (!sceneManager || (isToolSeeding && isTouchModeState)) return

		// get mouse position in screen normalized coordinates [-1; 1]
		mousePos.current = {
			x: (evt.clientX / window.innerWidth) * 2 -1,
			y: - (evt.clientY / window.innerHeight) * 2 + 1,
		}

		checkIntersection()
	}

	const onTouchStart = (evt: React.TouchEvent<HTMLCanvasElement>) => {
		touchPos.current = {
			x: evt.touches[0].clientX,
			y: evt.touches[0].clientY,
		}

		if (!sceneManager) return 
		sceneManager.camera.alphaAdjust = 0
		sceneManager.camera.yAdjust = 0
	}

	const onTouchMove = (evt: React.TouchEvent<HTMLCanvasElement>) => {
		if (!sceneManager) return

		const current = {
			x: evt.touches[0].clientX,
			y: evt.touches[0].clientY,
		}
		const dx = current.x - touchPos.current!.x
		const dy = current.y - touchPos.current!.y
		touchPos.current = current

		sceneManager.camera.alphaAdjust-= dx * 0.0003
		sceneManager.camera.yAdjust-= dy * 0.01
	}

	// triggers the visibility of the seeding cursor
	useEffect(() => {
		if (!sceneManager) return
		sceneManager.setSeedingVisible(false)
		// is there an intersection with a Tile of the environment, if so which one
		const intersection = sceneManager.getCylinderTiles().getTilesCoordinatesFromMouse(0, 0)
		// toggle seeding mode
		sceneManager.camera.toggleSeedingMode(isToolSeeding, intersection?.point)
		// observe a change in camera motion when seeding
		sceneManager.camera.seedingMotionObservable.subscribe(() => checkIntersection())
		// move the seeding cursor on intersection, TOUCH DEVICES friendly
		if (isToolSeeding) {
			mousePos.current = { x: 0, y: 0 }
			if (intersection) checkIntersection()
		}
		// store the tool seeding value into the reference
		isToolSeedingRef.current = isToolSeeding
	}, [isToolSeeding, sceneManager])

	useEffect(() => {
		if (!sceneManager) return
		sceneManager.getSeedsManager().hoverSeed(seedHovered)
	}, [seedHovered, sceneManager])

	// set the seeds visibility based on the state of the tool
	useEffect(() => {
		if (!sceneManager) return
		sceneManager.getSeedsManager().setSeedsVisible(userSettings.showSeeds)
	}, [userSettings, sceneManager])

	return (
		<>
			<canvas 
				ref={ref} 
				onClick={onCanvasClick} 
				onMouseMove={onMouseMove} 
				onTouchStart={onTouchStart}
				onTouchMove={onTouchMove}
			/>
			{isToolSeeding && isTouchModeState && (
				<StBtnCont>
					<Button 
						type="button"
						icon="seedling"
						onClick={onMobileAddSeed}
						ref={addSeedBtnRef}
					>
						Plant seed
					</Button>
				</StBtnCont>
			)}
		</>
	)
})