import { Vector2 } from "three";

var NoiseShader = {

	uniforms: {

		'tDiffuse': { value: null },
		'uTime': { value: 0 },
		'uIntro': { value: 0 },
		'uResolution': { value: new Vector2() }

	},

	vertexShader: /* glsl */`
		varying vec2 vUv;
		void main() {
			vUv = uv;
			gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
		}`,

	fragmentShader: /* glsl */`
		uniform float uTime;
		uniform float uIntro;
		uniform vec2 uResolution;

		uniform sampler2D tDiffuse;
		varying vec2 vUv;
		float rand(vec2 n) { 
			return fract(sin(dot(n, vec2(12.9898, 4.1414))) * 43758.5453);
		}
		void main() {
			vec2 uv05 = vUv - 0.5;
			uv05.x*= uResolution.x / uResolution.y;
			float R = max(uResolution.x/uResolution.y, uResolution.x/uResolution.y);
			float L = length(uv05);
			float S = smoothstep(uIntro*R, uIntro*R-0.1, L);

			vec4 texel = texture2D( tDiffuse, vUv );
			float N = rand(vUv + vec2(0., uTime)) * 0.05;
			gl_FragColor = (texel + N) * uIntro;

			//gl_FragColor = vec4(uv05.x, uv05.y, S, 1.0);
		}`

};

export { NoiseShader };