import axios from "axios"
import { useState } from "react"
import styled from "styled-components"
import { Sizes } from "Theme/Sizes"
import { Seed } from "types/Seed"
import useAsyncEffect from "use-async-effect"
import { Loader } from "./Loader"


const StCont = styled.div`
	width: 100%;
	height: 100%;
	background: white;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	color: black;
	padding: 20px;
	align-items: center;

	.quote {
		margin-top: 20px;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		justify-content: center;
		max-width: 100%;
		overflow-wrap: break-word;

		p {
			margin: 0 20px;
		}
	}

	.image-container {
		margin-top: 20px;
		flex-grow: 100;
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		.image-wrapper {
			height: 100%;
			width: 100%;
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		img {
			max-width: 100%;
			max-height: 100%;
		}
	}

	@media (max-width: 768px) {
		padding: 10px;

		.quote {
			font-size: ${Sizes.small};
		}
	}
`

interface IProps {
	seed: Seed
}

export function SeedMemory({ seed }: IProps) {
	const [imageUrl, setImageUrl] = useState<string|null>(null)

	useAsyncEffect(async (isMounted) => {
		if (seed.hasImage) {
			try {
				const response = await axios.get(`${process.env.REACT_APP_API_URL}/seed-image/${seed.id}`)
				if (isMounted()) {
					setImageUrl(response.data.url)
				}
			}
			catch (error) {
				console.log({ error })
			} 
		}
	}, [])
	
	return (
		<StCont>
			<strong>{ seed.author }</strong>
			{seed.message && (
				<div className="quote">
					<span>&#10077;</span>
					<p>{ seed.message }</p>
					<span style={{ alignSelf: "flex-end" }}>&#10078;</span>
				</div>
			)}
			{seed.hasImage && (
				<div className="image-container">
					{
						imageUrl ? (
							<div className="image-wrapper">
								<img src={imageUrl} alt="" />
							</div>
						):(
							<Loader color="black" />
						)
					}
				</div>
			)}
		</StCont>
	)
}