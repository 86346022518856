import { ToolIcon } from "Components/ToolIcon"
import { Sequencer } from "Logic/Sequencer"
import { useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import { touchModeState } from "State/TouchMode"
import { userSettingsState } from "State/UserSettings"
import styled from "styled-components"
import { Sizes } from "Theme/Sizes"
import { Tool } from "../Components/Tool"
import { toolState } from "../State/Tool"
import { ToolTypes } from "../types/Tools"


interface IStProps {
	show: boolean
}

const StCont = styled.aside<IStProps>`
	position: fixed;
	bottom: 20px;
	right: 20px;
	transition: all 0.5s ease-out;
	opacity: ${props => props.show ? 1 : 0};
	transform: translateX(${props => props.show ? "0" : "100%"});
	z-index: 2;
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	& > * + * {
		margin-top: 10px;
	}

	@media (max-width: 768px) {
		bottom: 10px;
		right: 10px;
	}
`

const StToolInfo = styled.aside<IStProps>`
	position: fixed;
	bottom: 20px;
	left: 20px;
	transition: all 0.5s ease-out;
	opacity: ${props => props.show ? 1 : 0};
	transform: translateX(${props => props.show ? "0" : "-100%"});
	background: rgba(0, 0, 0, 0.8);
	color: white;
	max-width: 50%;
	font-size: ${Sizes.small};
	pointer-events: none;
	padding: 15px;
	z-index: 1;

	p {
		margin: 0;
	}

	@media (max-width: 768px) {
		font-size: ${Sizes.smaller};
		bottom: 60px;
		left: 0;
		width: 100%;
		max-width: 100%;
		background: rgba(0, 0, 0, 0.6);
	}
`

interface IProps {
	hide?: boolean
}

export function Toolbox({ hide }: IProps) {
	const [activeTool, setActiveTool] = useRecoilState(toolState)
	const [_, setIsTouchMode] = useRecoilState(touchModeState)
	const [userSettings, setUserSettings] = useRecoilState(userSettingsState)
	const [show, setShow] = useState<boolean>(false)

	useEffect(() => {
		Sequencer.subscribe(changes => {
			const tar = changes.find(change => change.name === "interface")
			if (tar) {
				setShow(tar.state)
			}
		})
	}, [])

	return (
		<>
			<StCont show={hide ? false : show}>
				<ToolIcon
					active={userSettings.showSeeds}
					icon="eye"
					activeIcon="eye-slash"
					onToggle={(showSeeds) => setUserSettings({ ...userSettings, showSeeds })}
					title="show / hide the seeds planted"
				/>
				<Tool 
					title="ADD A SEED"
					activeTitle="CANCEL SEEDING"
					active={activeTool === ToolTypes.SEEDING}
					onToggle={(active) => setActiveTool(active ? ToolTypes.SEEDING : ToolTypes.NONE)}
					onTouchEnd={() => setIsTouchMode(true)}
				>
					<i className="fas fa-plus-circle"/>
				</Tool>
			</StCont>

			<StToolInfo show={activeTool === ToolTypes.SEEDING}>
				<p>
					A seed can only be planted close to where the current structure has grown. You can try to elevate the structure or change the shape of a particular section you dislike.
				</p>
			</StToolInfo>
		</>
	)
}