import { atom } from "recoil"

export interface UserSettings {
	showSeeds: boolean
}

export const userSettingsState = atom<UserSettings>({
	key: "USER_SETTINGS",
	default: {
		showSeeds: true
	}
})