import React, { useCallback, useRef, useState } from "react"
import styled, { keyframes } from "styled-components"
import axios from "axios"
import { IVector2 } from "../Utils/Vector2d"
import { FormEntry } from "../Components/FormEntry"
import { FormSubmit } from "../Components/FormSubmit"
import { FileRejection, useDropzone } from "react-dropzone"
import { classes } from "Utils/Directives"
import { Cover } from "Components/Cover"
import { Sizes } from "Theme/Sizes"
import { Colors } from "Theme/Colors"
import { Button } from "Components/Button"
import { useRecoilState } from "recoil"
import { toolState } from "State/Tool"
import { ToolTypes } from "types/Tools"
import { Seed } from "types/Seed"
import { fitImageFileToRect } from "Utils/Images"


const KfPop = keyframes`
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.05);
	}
	100% {
		transform: scale(1);
	}
`

const StForm = styled.form`
	position: fixed;
	z-index: 10;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: white;
	color: black;
	display: flex;
	flex-direction: column;
	padding: 20px;
	border-radius: 6px;
	width: 600px;
	box-shadow: 0 4px 40px -12px rgba(0, 0, 0, 0.9);

	@media (max-width: 600px) {
		width: 100%;
		height: 100vh;
		top: 0;
		left: 0;
		transform: none;
		overflow: auto;
	}

	header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		span {
			font-size: ${Sizes.small};
		}
	}

	.infos {
		font-size: ${Sizes.smaller};
		color: #484848;
		margin-top: 2em;
	}

	.top-level-error {
		color: ${Colors.error};
		font-weight: bold;
	}
	
	.success-message {
		font-weight: 600;
		color: ${Colors.primary};
		display: flex;
		flex-direction: column;
		align-items: center;

		i {
			font-size: 80px;
		}

		p {
			color: black;
		}

		button {
			margin-top: 2em;
		}
	}

	input, textarea {
		border: 2px solid black;
		border-radius: 6px;
		padding: 1em;
	}

	input {
		height: 40px;
	}

	textarea {
		resize: none;
		height: 83px;
	}

	input, textarea {
		:focus {
			outline: none;
			border-color: #e79d1a;
		}
	}

	.image-container {
		width: 100%;
		height: 150px;
		background: black;
		color: white;
		border-radius: 6px;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px;
		cursor: pointer;
		font-size: ${Sizes.small};
		position: relative;

		&.dragging {
			background-color: ${Colors.primary};
			animation: ${KfPop} 0.2s ease-in 0s 1 forwards;

			p {
				font-weight: bold;
			}
		}

		p {
			padding: 2em;
		}

		&::before {
			content: "Click to change the image";
			opacity: 0;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			background: rgba(0, 0, 0, 0.3);
			pointer-events: none;
			transition: all 0.3s ease-out;
			display: flex;
			align-items: center;
			justify-content: center;
			font-weight: bold;
		}

		&.loaded {
			padding: 0;
			border: none;

			p {
				display: none;
			}

			&:hover::before {
				opacity: 1;
			}
		}

		img {
			object-fit: contain;
			max-width: 100%;
			max-height: 100%;
		}
	}
`

interface Props {
	position: IVector2
	onClose: () => void
	onWatchSeed: (seed: Seed) => void
}

export function SeedForm({ 
	position,
	onClose,
	onWatchSeed,
}: Props) {
	const [imageFile, setImageFile] = useState<File>()
	const [imageLocalUrl, setImageLocalUrl] = useState<string>()
	const [author, setAuthor] = useState<string>("")
	const [message, setMessage] = useState<string>("")
	const [errors, setErrors] = useState<Record<string, string|undefined>>({})

	// general errors and success messages
	const [generalError, setGeneralError] = useState<string>()
	const [successMessage, setMessageSuccess] = useState<string>()
	const [tempSeedCreated, setTempSeedCreated] = useState<Seed>()

	// tool state
	const [_, setActiveTool] = useRecoilState(toolState)

	// drag n drop
	const onDrop = async (files: File[]) => {
		if (files.length > 0) {
			let file = files[0]

			// if the size of the file > 1Mb, then we resize the file
			if (file.size > 1048576) {
				file = await fitImageFileToRect(file, 512, 512)
			}

			const url = URL.createObjectURL(file)
			setImageFile(file)
			setImageLocalUrl(url)
			setErrors({
				...errors,
				image: undefined
			})
		}
	}
	const onDropRejected = (fileRejections: FileRejection[]) => {
		if (fileRejections.length > 0) {
			const error = fileRejections[0].errors[0].code
			let errorMessage
			switch (error) {
				case "file-too-large":
					errorMessage = "Maximum size of 10Mb"
					break
				case "too-many-files":
					errorMessage = "Only 1 image is accepted"
					break
				case "file-invalid-type":
					errorMessage = "Only .png and .jpeg files are accepted"
					break
				default:
					errorMessage = "Something went wrong. Try again with another file"
					break
			}
			setErrors({
				...errors,
				image: errorMessage
			})
		}
	}
	const { getRootProps, getInputProps, isDragActive } = useDropzone({ 
		onDrop,
		onDropRejected,
		accept: "image/jpeg, image/png",
		maxFiles: 1,
		multiple: false,
		maxSize: 10485760,		// 10 Mb, will eventually be downsized to max 1Mb
	})

	// derive the number of errors from the Record of errors
	const nbErrors = Object.keys(errors).reduce((prev, curr) => errors[curr] ? prev+1 : prev, 0)

	const validateAuthor = (value: string) => {
		if (value.length === 0) 
			return "Required!"
		if (value.length > 20)
			return "Max. 20 characters"	
		return undefined
	}

	const onChangeAuthor = (value: string) => {
		setErrors({
			...errors,
			author: validateAuthor(value)
		})
		setAuthor(value)
	}

	const validateMessage = (value: string) => {
		if (value.length > 200) 
			return "Max. 200 characters"
		return undefined
	}

	const onChangeMessage = (value: string) => {
		setErrors({
			...errors,
			message: validateMessage(value)
		})
		setMessage(value)
	}

	const onSend = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		// remove the errors
		setGeneralError(undefined)

		// validate the author input
		const errorAuthor = validateAuthor(author)
		if (errorAuthor) {
			setErrors({
				...errors,
				author: errorAuthor
			})
			return
		}

		// if there are errors, no send
		if (nbErrors > 0) return

		const formData = new FormData()
		formData.append("author", author)
		formData.append("message", message)
		formData.append("positionX", position.x.toString())
		formData.append("positionY", position.y.toString())
		if (imageFile) {
			formData.append("image", imageFile, imageFile.name)
		}

		try {
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/seed`, formData)
			setMessageSuccess(response?.data?.message || "Your seed was added in the environment")
			setTempSeedCreated(response.data.seed)
			setActiveTool(ToolTypes.NONE)
		}
		catch (error) {
			setGeneralError(error.response?.data?.message || "Please try again.")
		}
	}

	const onWatchGrowth = () => {
		if (tempSeedCreated) onWatchSeed(tempSeedCreated)
		onClose()
	}

	return (
		<>
			<Cover onClick={onClose} />

			<StForm method="post" onSubmit={onSend}>
				{successMessage ? (
					<div className="success-message">
						<i className="fas fa-check-circle"/>
						<p>{successMessage}</p>
						<Button onClick={onWatchGrowth}>Watch it grow</Button>
					</div>
				):(
					<>
						<header>
							<h2>Add your seed</h2>
							<span>[{ position.x }; { position.y }]</span>
						</header>

						<p className="infos">
						You may leave a memory attached to your seed. Your memory will be visible by other people visiting or contributing to this virtual space. <strong>The more intense will the memory left along the seed be, the more the seed will spread.</strong>
						</p>

						{generalError && (
							<p className="top-level-error">
								An error occured when puting your seed in the environment: <br/>
								{generalError}
							</p>
						)}

						<FormEntry error={errors.author}>
							<label htmlFor="author">Name *</label>
							<input 
								type="text" 
								id="author" 
								name="author" 
								placeholder="Anyname"
								value={author} 
								onChange={(e) => onChangeAuthor(e.target.value)} 
								maxLength={20} 
							/>
						</FormEntry>

						<FormEntry error={errors.message}>
							<label htmlFor="message">Message</label>
							<textarea
								id="message"
								name="message"
								placeholder="Leave a message with your seed"
								value={message}
								onChange={(event) => onChangeMessage(event.target.value)}
								maxLength={200}
							/>
						</FormEntry>

						<FormEntry error={errors.image} layout="horizontal">
							<label>Image (png|jpeg)</label>
							<div {...getRootProps()} className={classes({
								"image-container": true,
								"loaded": !!imageLocalUrl,
								"dragging": isDragActive,
							})}>
								<input {...getInputProps()} />
								{imageLocalUrl && <img src={imageLocalUrl} />}
								{
									isDragActive 
										? <p>Drop your image here</p>
										: <p>Drag 'n' drop an image here, or click to select a file</p>
								}
							</div>
						</FormEntry>

						<FormSubmit>
							<Button type="button" theme="cancel-light" icon="caret-left" onClick={onClose}>CANCEL</Button>
							<Button type="submit" disabled={nbErrors > 0}>ADD YOUR SEED</Button>
						</FormSubmit>
					</>
				)}
			</StForm>
		</>
	)
}