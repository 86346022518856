import { Observable } from "Utils/Observable"


interface Sequence {
	name: string
	start: number
	duration: number | false
}

const SEQUENCES: Sequence[] = [
	{
		name: "introduction-1",
		start: 0,
		duration: 8000,
	},
	{
		name: "introduction-2",
		start: 7800,
		duration: 11000,
	},
	{
		name: "interface",
		start: 17000,
		duration: false,
	}
]

export interface SequenceChangeEvent {
	name: string
	state: boolean
}

/**
 * The Sequencer is responsible for handling the logic behind the introduction, it triggers when some components are displayed
 * and when they are hidden.
 */
class SequencerSingleton {
	time: number = 0
	sequencesState: Record<string, boolean>
	maxFinishTime: number
	sequenceChangeObservable: Observable<SequenceChangeEvent[]>

	constructor() {
		// initialize the state of the sequences
		this.sequencesState = {}
		for (const sequence of SEQUENCES) {
			this.sequencesState[sequence.name] = false
		}

		this.sequenceChangeObservable = new Observable<SequenceChangeEvent[]>()

		// compute the maximum possible duration
		let maxEnd = 0
		for (const sequence of SEQUENCES) {
			maxEnd = Math.max(maxEnd, sequence.start + (sequence.duration || 0))
		}
		this.maxFinishTime = maxEnd
	}

	subscribe(handler: (event: SequenceChangeEvent[]) => void) {
		this.sequenceChangeObservable.subscribe(handler)
		handler(Object.keys(this.sequencesState).map(name => ({
			name,
			state: this.sequencesState[name],
		})))
	}

	update = (time: number) => {
		// prevent useless computations once the introduction is finished
		if (time > this.maxFinishTime + 1000) return

		// compute the state of each sequence, and fire the changes to subscribers
		this.time = time
		const changes: SequenceChangeEvent[] = []
		for (const sequence of SEQUENCES) {
			// compute the state of the sequence, is it active or not
			const state = this.time > sequence.start && (!sequence.duration || (this.time < sequence.start + sequence.duration))
			// if there is a change, apply it and add it to the change list
			if (state !== this.sequencesState[sequence.name]) {
				this.sequencesState[sequence.name] = state
				changes.push({
					name: sequence.name,
					state
				})
			}
		}
		// if there are changes, fire to the observers
		if (changes.length > 0) {
			this.sequenceChangeObservable.fire(changes)
		}
	}
}

export const Sequencer = new SequencerSingleton()