import { Config3d } from "Config/3d"
import * as THREE from "three"
import { CylinderTile } from "./CylinderTile"
import { Vector2d } from "Utils/Vector2d"
import DawConfig from "Config/daw"
import { TileUpdate } from "types/tile"
import { TileCoordinates, TileIntersection } from "types/TileCoordinates"


const rayCaster = new THREE.Raycaster()

/**
 * The object CylinderTiles will handle the construction of the faces based on the Tiles it receives as an input
 */
export class CylinderTiles {
	size: Vector2d
	scene: THREE.Scene
	camera: THREE.Camera
	tileHeight: number
	// tiles are stored in a 2D array the way they are placed in the 2D space
	tiles: CylinderTile[][]
	
	constructor(scene: THREE.Scene, camera: THREE.Camera) {
		this.scene = scene
		this.camera = camera
		this.tiles = []
		this.size = this.getEnvironmentSize()
		// derive the height of a Tile based on the rows and the radius
		this.tileHeight = (2 * Math.PI * Config3d.CYLINDER_RADIUS) / DawConfig.TILES_PER_ROW
	}

	/**
	 * Assign to the Tile buffer the buffer specified in parameters. Replaces the current Buffer of the Tile
	 * @param id the string identifiers (ie: coordinates) of the tile to set the raw buffer data
	 * @param buffer the raw buffer which will be set as the buffer of the Tile
	 * @param version the version number
	 */
	 setTileRawBuffer(id: string, buffer: Uint8Array, version: number) {
		const [x, y] = id.split(":").map(i => parseInt(i))

		// first check if the tile exists, if it doesn't then the environment array has to be updated
		if (!this.tiles[x] || !this.tiles[x][y]) {
			this.updateEnvironment(x, y)
		}

		// change the buffer of the Tile
		this.scene.add(this.tiles[x][y].mesh!)
		this.tiles[x][y].setBuffer(buffer, version)
	}

	/**
	 * Creates a new Tile at [x; y] coordinates, and updates the environment if necessary
	 * @param x the X coordinate of the Tile to add in the environment
	 * @param y the Y coordinate of the Tile to add in the environment
	 */
	 updateEnvironment(x: number, y: number) {
		// allocate a new row if required
		if (!this.tiles[x]) {
			this.tiles[x] = []
		}

		// add a new Tile at given coordinates
		this.tiles[x][y] = new CylinderTile(`${x}:${y}`, this.tileHeight)
		this.size = this.getEnvironmentSize()
	}

	/**
	 * 
	 * @param id the string identifiers (ie: coordinates) of the tile to set the raw buffer data
	 * @param updates a list of the updates to apply to the buffer of the Tile
	 */
	updateTileBuffer(id: string, updates: TileUpdate[]) {
		const [x, y] = id.split(":").map(i => parseInt(i))
		for (const update of updates) {
			this.tiles[x][y].updateBuffer(update.blocks, update.version)
		}
	}

	/**
	 * Computes and returns the size of the environment based on the number of tiles on each dimension and the size of a tile
	 * @returns a 2D vector containing the total size of the environment
	 */
	getEnvironmentSize(): Vector2d {
		let maxY = 0
		for (let col of this.tiles) {
			if (!col) continue
			if (col.length > maxY) maxY = col.length
		}

		return new Vector2d(
			this.tiles ? this.tiles.length * DawConfig.TILE_SIZE : 0,
			maxY * DawConfig.TILE_SIZE
		)
	}

	/**
	 * @returns an array of the meshes of the Tiles in the scene
	 */
	getTilesMeshes(): THREE.Mesh[] {
		const meshes: THREE.Mesh[] = []
		for (const row of this.tiles) {
			for (const tile of row) {
				if (tile) meshes.push(tile.mesh!)
			}
		}
		return meshes
	}

	getTilesCoordinatesFromMouse(x: number, y: number): TileIntersection|null {
		const mouse = new THREE.Vector2(x, y)
		rayCaster.setFromCamera(mouse, this.camera)

		// calculates objects intersecting
		const intersects = rayCaster.intersectObjects(this.getTilesMeshes())
		if (intersects.length > 0) {
			const intersect = intersects[0]
			// get the Tile coordinates
			const [x, y] = intersect.object.name.split(":").map(coord => parseInt(coord))
			// get the coordinates within the Tile space
			const [dx, dy] = [ (intersect.uv!.x * DawConfig.TILE_SIZE) | 0, (intersect.uv!.y * DawConfig.TILE_SIZE) | 0 ]

			return { 
				coordinates: { x, y, dx, dy },
				point: intersect.point
			}
		}

		// nothing has been reached
		return null
	}

	/**
	 * Given (x; y) coordinates, return new {x,y} coordinates where X loops on the edges of the enviornment and Y is clamped to 
	 * the edges of the environement
	 * @param x the x coordinates
	 * @param y the y coordinates
	 */
	 semiTorusCoordinates(x: number, y: number): Vector2d {
		const torus = new Vector2d(x, y)

		// X loops on the edges
		if (torus.x < 0) torus.x = this.size.x + torus.x
		if (torus.x >= this.size.x) torus.x = torus.x % this.size.x
		// y is clamped
		if (torus.y < 0) torus.y = 0
		if (torus.y >= this.size.y) torus.y = this.size.y-1

		return torus
	}

	/**
	 * Returns the Tile coordinates based on the world coordinates given as parameter. The coordinates are first turned into 
	 * Torus coordinates.
	 * @param x the X world coordinate
	 * @param y the Y world coordinate
	 */
	 getTileCoordinates(x: number, y: number): TileCoordinates {
		const torus = this.semiTorusCoordinates(x, y)

		return {
			// find the Tile in which those coordinates land
			x: (torus.x / 128) | 0,
			y: (torus.y / 128) | 0,
			// then the coordinates of the point within the Tile space
			dx: (torus.x % 128) | 0,
			dy: (torus.y % 128) | 0
		}
	}

	/**
	 * Given some [x; y] coordinates of a point in the Tiles space, returns 3D coordinates where the point of the Tile is in
	 * the 3D space
	 * @param x 
	 * @param y 
	 */
	tile2dToWorldCoordinates(x: number, y: number): THREE.Vector3 {
		// the angle of the cylinder in which X coordinates is
		const a = x / (DawConfig.TILE_SIZE * DawConfig.TILES_PER_ROW) * 2 * Math.PI
		return new THREE.Vector3(
			Math.cos(a) * Config3d.CYLINDER_RADIUS,
			(y / DawConfig.TILE_SIZE) * this.tileHeight,
			Math.sin(a) * Config3d.CYLINDER_RADIUS,
		)
	}

	/**
	 * Samples the environment at a given some Tile coordinates
	 */
	sampleEnvironment(coords: TileCoordinates): number {
		return this.tiles[coords.x][coords.y] ? this.tiles[coords.x][coords.y].getDataAt(coords.dx, coords.dy) : 0
	}

	/**
	 * Can a seed be placed in the environment ? Requires a seed to be placed close to some already aggregated substrate.
	 * @param tileCoordinates the coordinates of the tile, as well as the coordinates within the Tile space
	 */
	canSeedBePlaced(tileCoordinates: TileCoordinates): boolean {
		// turn the tile coordinates into pseudo-2d-world coordinates
		const worldCoordinates = {
			x: tileCoordinates.x * DawConfig.TILE_SIZE + tileCoordinates.dx,
			y: tileCoordinates.y * DawConfig.TILE_SIZE + tileCoordinates.dy,
		}

		// the parameters of the square containing the radius in which we perform the search
		const radius = DawConfig.SEED_NEIGHBOORING_RADIUS
		const rad2 = radius**2
		const xStart = Math.floor(worldCoordinates.x - radius)
		const xEnd = Math.ceil(worldCoordinates.x + radius)
		const yStart = Math.max(0, (worldCoordinates.y-radius) | 0)
		const yEnd = Math.ceil(worldCoordinates.y + radius)

		// loop through all the pixels of the search
		for (let dx = xStart; dx < xEnd; dx++) {
			for (let dy = yStart; dy < yEnd; dy++) {
				// check if the point is in the circle
				if ((dx-worldCoordinates.x)**2 + (dy-worldCoordinates.y)**2 <= rad2) {
					// get the Tile coordinates of the point to search
					const coords = this.getTileCoordinates(dx, dy)
					// check if there is some aggregate in the environment
					const sample = this.sampleEnvironment(coords)
					if (sample > 0) {
						return true
					}
				}
			}
		}
		return false
	}
}