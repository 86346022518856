import { v4 as uuid } from "uuid"
import { blobToFile } from "./Files"


export function loadImage(url: string): Promise<HTMLImageElement> {
	return new Promise((resolve, reject) => {
		const image = new Image()
		image.onload = () => resolve(image)
		image.onerror = () => reject()
		image.src = url
	})
}

export async function loadImageFromFile(imageFile: File): Promise<HTMLImageElement> {
	return loadImage(URL.createObjectURL(imageFile))
}

export function canvasToBlob(canvas: HTMLCanvasElement): Promise<Blob> {
	return new Promise((resolve) => {
		canvas.toBlob((blob) => resolve(blob!), "image/jpeg", 0.95)
	})
}

/**
 * from [https://stackoverflow.com/questions/3971841/how-to-resize-images-proportionally-keeping-the-aspect-ratio]
 */
function calculateAspectRatioFit(srcWidth: number, srcHeight: number, maxWidth: number, maxHeight: number) {
	const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight)
	return { width: srcWidth*ratio, height: srcHeight*ratio }
}

/**
 * Given an url to an image resource (even locally), loads the image in an <img> element, determine its size. If such image 
 * exceeds the maximum dimensions, create a canvas to a size in which the image will fit in respect to its proportions. The
 * image is written to the canvas from which a new URL is obtained
 */
export async function fitImageFileToRect(imageFile: File, maxWidth = 1024, maxHeight = 1024): Promise<File> {
	const image = await loadImageFromFile(imageFile)
	if (image.width > maxWidth || image.height > maxHeight) {
		const targetSize = calculateAspectRatioFit(image.width, image.height, maxWidth, maxHeight)
		const cvs = document.createElement("canvas")
		const ctx = cvs.getContext("2d")
		cvs.width = targetSize.width
		cvs.height = targetSize.height
		ctx!.drawImage(image, 0, 0, cvs.width, cvs.height)
		const blob = await canvasToBlob(cvs)
		cvs.remove()
		return blobToFile(blob, `${uuid()}.jpg`)
	}
	else {
		return imageFile
	}
}