import * as THREE from "three"
import { Config3d } from "Config/3d"
import DawConfig from "Config/daw"
import { TileDataUpdate } from "types/tile"

/**
 * A Cylinder Tile is a small section of the Cylinder which handles the display of a single Tile.
 * The Mesh is generated when the object is instanciated and it is responsible for upating the texture Buffer if the Buffer
 * of a Tile is updated.
 */
export class CylinderTile {
	// the ID of the tile, encoded as x:y
	id: string
	// the coordinates
	coords: { x: number, y: number }
	// the version of the tile
	version: number
	// the buffer in which the texture will be stored and manipulated
	buffer?: Uint8Array
	size: number
	height: number
	mesh?: THREE.Mesh
	material?: THREE.MeshBasicMaterial

	constructor(id: string, height: number) {
		this.id = id
		this.coords = {
			x: parseInt(this.id.split(":")[0]),
			y: parseInt(this.id.split(":")[1])
		}
		this.version = -1
		this.size = 128
		this.height = height
		this.generateMesh()
	}

	/**
	 * Generates the Mesh of the Tile: a square section of the Cylinder
	 * Updates the .mesh property of the object
	 */
	generateMesh() {
		// ! allocate the buffers used for the geometry
		const geometry = new THREE.BufferGeometry()
		
		// the number of segments (both horizontal and vertical)
		const NB_SEGMENTS = Config3d.TILE_SEGMENTS
		
		// how many vertices are there ?
		const nVertices = NB_SEGMENTS ** 2
		// allocate the vertices array
		const vertices = new Float32Array(nVertices * 3)	// each vertex has 3 coordinates
		const uvs = new Float32Array(nVertices * 2)
		
		// how many faces are there ?
		const nFaces = ((NB_SEGMENTS-1) ** 2) * 2
		const indices = new Uint16Array(nFaces * 3)		// each face has 3 points
		
		// ! build the geometry with vertexes and indices
		// the angle from the start to the end of the Tile
		const totalAngle = (2*Math.PI) / DawConfig.TILES_PER_ROW
		const startAngle = totalAngle * this.coords.x
		
		// build the vertices buffer
		let vx, vy, vz
		for (let vs = 0; vs < NB_SEGMENTS; vs++) {
			// find the angle of the vertical segment
			const va = startAngle + (vs/(NB_SEGMENTS-1)) * totalAngle
			// compute [x; z] based on the vertical angle
			vx = Math.cos(va) * Config3d.CYLINDER_RADIUS
			vz = Math.sin(va) * Config3d.CYLINDER_RADIUS
			// loops through each horizontal segment
			for (let hs = 0; hs < NB_SEGMENTS; hs++) {
				vy = ((hs / (NB_SEGMENTS-1)) + this.coords.y) * this.height
				// assign vertex data in the vertices array, at the right position
				let idx = (vs * NB_SEGMENTS + hs) * 3
				vertices[idx] = vx
				vertices[idx + 1] = vy
				vertices[idx + 2] = vz 
				// assign vertex uvs at the same time
				idx = (vs * NB_SEGMENTS + hs) * 2
				uvs[idx] = vs / (NB_SEGMENTS-1)
				uvs[idx + 1] = hs / (NB_SEGMENTS-1)
			}
		}

		// build the indices buffer
		for (let vf = 0; vf < NB_SEGMENTS-1; vf++) {
			for (let hf = 0; hf < NB_SEGMENTS-1; hf++) {
				const faceIdx = vf * (NB_SEGMENTS-1) + hf
				const idx = faceIdx * 6
				// face A
				indices[idx] = faceIdx + vf
				indices[idx + 1] = faceIdx + vf + 1
				indices[idx + 2] = faceIdx + vf + 6
				// face B
				indices[idx + 3] = faceIdx + vf
				indices[idx + 4] = faceIdx + vf + 6
				indices[idx + 5] = faceIdx + vf + 5
			}
		}

		// assign the buffers to the BufferGeometry
		geometry.setIndex(new THREE.BufferAttribute(indices, 1))
		geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3))
		geometry.setAttribute("uv", new THREE.BufferAttribute(uvs, 2))

		this.material = new THREE.MeshBasicMaterial({
			color: 0xffffff,
			side: THREE.DoubleSide,
		})
		this.mesh = new THREE.Mesh(geometry, this.material)
		this.mesh.name = this.id
	}

	/**
	 * Rewrites the whole buffer of the Tile with some raw data
	 * @param buffer the buffer to set into the tile
	 */
	 setBuffer(buffer: Uint8Array, version: number) {
		if (this.version >= version) {
			console.log("Warning: the local version is higher or equal to the version received")
		}
		else {
			this.buffer = buffer
			this.version = version
			const texture = new THREE.DataTexture(this.buffer, 128, 128, THREE.LuminanceFormat)
			//texture.magFilter = THREE.LinearFilter
			texture.minFilter = THREE.LinearFilter
			// @ts-ignore
			// eslint-disable-next-line 
			this.material!.map = texture
			this.material!.setValues({
				map: texture
			})
		}
	}

	/**
	 * Updates the raw buffer of the Tile with a series of updates made to some indexes of the Buffer
	 * @param updates the update, a list of "pixels" which should be updated
	 * @param version the version of the update
	 */
	 updateBuffer(updates: TileDataUpdate[], version: number) {
		if (this.version >= version) {
			console.error("Warning: the local version is higher or equal to the version received")
		}
		else if (!this.buffer) {
			console.error("Cannot update an non-existing buffer. Data has never bet set in the first place")
		}
		else {
			for (const update of updates) {
				this.buffer[update.index] = update.value
			}
			this.version = version
			this.material!.map!.needsUpdate = true
		}
	}

	/**
	 * Turns 2D coordinates into 1D coordinates
	 */
	 coords2dTo1d(x: number, y: number) {
		return x + y * this.size
	}

	/**
	 * returns the data at given 2D coordinates
	 */
	getDataAt(x: number, y: number) {
		return this.buffer ? this.buffer[this.coords2dTo1d(x, y)] : 0
	}
}