import styled, { keyframes } from "styled-components"

const KfWheel = keyframes`
	0% {
		transform: translate(-50%, 0px) scaleY(1);
		opacity: 1;
	}
	70% {
		transform: translate(-50%, 12px) scaleY(1);
		opacity: 1;
	}
	0% {
		transform: translate(-50%, 16px) scaleY(0.8);
		opacity: 0;
	}
`

const StIcon = styled.div`
	min-width: 30px;
	height: 45px;
	border: 2px solid white;
	border-radius: 40px;
	position: relative;

	::before {
		content: "";
		display: block;
		position: absolute;
		transform: translate(-50%, 0px) scaleY(1);
		left: 50%;
		top: 5px;
		animation: ${KfWheel} 1.5s ease-out 0s infinite reverse;
		width: 4px;
		height: 7px;
		background: white;
		border-radius: 4px;
	}
`

export function ScrollIcon() {
	return <StIcon />	
}