import React, { FunctionComponent } from "react"
import styled, { css } from "styled-components"
import { Colors } from "Theme/Colors"
import { Sizes } from "Theme/Sizes"


interface IProps {
	disabled?: boolean
	icon?: string
	theme?: "primary" | "cancel-light"
	type?: "submit" | "button"
	onClick?: () => void
	children: React.ReactNode
}

interface IStProps {
	disabled: boolean
	theme: "primary" | "cancel-light"
}

const StButton = styled.button<IStProps>`
	border: none;
	height: 40px;
	min-width: 210px;
	display: flex;
	flex-direction: row;
	align-items: center;
	text-transform: uppercase;
	color: white;
	border-radius: 6px;
	background: black;
	transition: all 0.2s ease-out;
	cursor: pointer;
	box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.25);
	font-size: ${Sizes.big};
	font-weight: 600;
	justify-content: center;
	padding: 0 1em;

	&:disabled {
		filter: grayscale(100%);
		cursor: not-allowed;
	}
	
	span {
		flex-grow: 1;
	}
	
	i {
		transition: all 0.2s ease-out;
	}

	&:hover:not(:disabled) {
		transform: scale(1.03);

		i {
			transform: translateX(-3px);
		}
	}

	${props => props.theme === "primary" && css`
		background: ${Colors.primary};
	`}

	${props => props.theme === "cancel-light" && css`
		background: #AFAFAF;

		&:hover {
			background: ${Colors.error};
		}
	`}

	@media (max-width: 768px) {
		font-size: ${Sizes.normal};
		min-width: auto;
		padding: 1em;

		i {
			margin-right: 10px;
		}
	}
`

export const Button = React.forwardRef<HTMLButtonElement, IProps>(({
	disabled = false,
	theme = "primary",
	type = "submit",
	icon,
	onClick,
	children
}, ref) => {
	return (
		<StButton type={type} onClick={onClick} theme={theme} disabled={disabled} ref={ref}>
			{icon && <i className={`fas fa-${icon}`} />}
			<span>{ children }</span>
		</StButton>
	)
})