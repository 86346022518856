export const Easings = {
  linear: (t: number) => t,
  quadIn: (t: number) => t*t,
  quadOut: (t: number) => t*(2-t),
  quadInOut: (t: number) => (t<0.5) ? 2*t*t : -1+(4-2*t)*t,
  cubicIn: (t: number) => t*t*t,
  cubicOut: (t: number) => (--t)*t*t+1,
  cubicInOut: (t: number) => t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1,
  quartIn: (t: number) => t*t*t*t,
  quartOut: (t: number) => 1-(--t)*t*t*t,
  quartInOut: (t: number) => t<.5 ? 8*t*t*t*t : 1-8*(--t)*t*t*t,
  quintIn: (t: number) => t*t*t*t*t,
  quintOut: (t: number) => 1+(--t)*t*t*t*t,
  quintInOut: (t: number) => t<.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t
}