import { Sequencer } from "Logic/Sequencer"
import { useEffect, useState } from "react"
import styled from "styled-components"
import { Colors } from "Theme/Colors"
import { Sizes } from "Theme/Sizes"


interface IStProps {
	show: boolean
}

const StHeader = styled.header<IStProps>`
	position: fixed;
	top: 20px;
	left: 12px;
	color: white;
	transition: all 0.5s ease-out;
	opacity: ${props => props.show ? 1 : 0};
	transform: translateX(${props => props.show ? "0" : "-100%"});
	
	h1 {
		margin: 0;
	}

	a {
		color: ${Colors.primary};
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}

	@media (max-width: 768px) {
		font-size: ${Sizes.small};
		top: 10px;
		left: 10px;
	}
`

export function Header() {
	const [show, setShow] = useState<boolean>(false)

	useEffect(() => {
		Sequencer.subscribe(changes => {
			const tar = changes.find(change => change.name === "interface")
			if (tar) {
				setShow(tar.state)
			}
		})
	}, [])

	return (
		<StHeader show={show}>
			<h1><mark>seed simul</mark></h1>
			<mark>
				<span>a work by <a href="https://ciphrd.com" target="_blank">ciphrd</a></span>
			</mark>
		</StHeader>
	)
}