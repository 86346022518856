import { IntroductionHeader } from "Components/IntroductionHeader"


interface IProps {
	show: boolean
}

export function Introduction1({ show }: IProps) {
	return (
		<IntroductionHeader
			title="Welcome to seed simul"
			show={show}
		>
			Seed Simul is a virtual space in which visitors are invited to collaborate together to grow a structure by planting seeds.
		</IntroductionHeader>
	)
}