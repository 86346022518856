import * as THREE from "three"
import { Config3d } from "../Config/3d"
import { Seed } from "../types/Seed"
import { CameraManager } from "./Objects/CameraManager"
import { CylinderTiles } from "./Objects/CylinderTiles"
import { SeedingCursor } from "./Objects/SeedingCursor"
import { SeedsManager } from "./Objects/SeedsManager"
import { Sequencer } from "./Sequencer"
// post processing
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer"
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass"
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass"
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass"
import { NoiseShader } from "./Shaders/NoiseShader"
import { Easings } from "Utils/Easings"


export class SceneManager {
	canvas: HTMLCanvasElement
	camera: CameraManager
	renderer: THREE.WebGLRenderer
	scene: THREE.Scene
	//controls: OrbitControls
	seedingCursor: SeedingCursor
	seedsManager: SeedsManager
	cylinderTiles: CylinderTiles

	// timers
	startTime: number
	lastFrameTime: number
	currentTime: number
	deltaTime: number

	// post-processing
	composer: EffectComposer
	noisePass: ShaderPass

	constructor(canvas: HTMLCanvasElement) {
		canvas.width = window.innerWidth
		canvas.height = window.innerHeight
		this.canvas = canvas

		this.camera = new CameraManager(canvas)
		this.scene = new THREE.Scene()

		// create the Tiles Manager
		this.cylinderTiles = new CylinderTiles(this.scene, this.camera.get())

		// create the seeding cursor
		this.seedingCursor = new SeedingCursor()
		this.scene.add(this.seedingCursor.getMesh())

		// add a cylinder of reference to the scene
		this.addReferenceCylinder()

		// setup the seeds manager
		this.seedsManager = new SeedsManager(this.scene, this.camera.get(), this.cylinderTiles)

		this.renderer = new THREE.WebGLRenderer({
			antialias: true,
			canvas: canvas
		})
		this.renderer.setSize(canvas.width, canvas.height)
		// this.controls = new OrbitControls(this.camera.get(), canvas)
		// this.controls.target = new THREE.Vector3(0, 10, 0)

		// ! setup post-processing
		this.composer = new EffectComposer(this.renderer)
		// the render pass, for the scene
		const renderPass = new RenderPass(this.scene, this.camera.get())
		this.composer.addPass(renderPass)
		// the bloom effect
		const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 0.6, 0.4, 0.1)
		this.composer.addPass(bloomPass)
		// the noise effect
		this.noisePass = new ShaderPass(NoiseShader)
		this.noisePass.uniforms.uResolution.value = new THREE.Vector2(window.innerWidth, window.innerHeight)
		this.composer.addPass(this.noisePass)

		// add resize listeners to update the renderer
		window.addEventListener("resize", () => {
			this.renderer.setSize(window.innerWidth, window.innerHeight)
			this.composer.setSize(window.innerWidth, window.innerHeight)
			this.camera.onWindowResize()
			bloomPass.resolution = new THREE.Vector2(window.innerWidth, window.innerHeight)
			this.noisePass.uniforms.uResolution.value = new THREE.Vector2(window.innerWidth, window.innerHeight)
		})

		// setup the timers
		this.startTime = this.lastFrameTime = this.currentTime = performance.now()
		this.deltaTime = 0

		this.animation()
	}

	getCylinderTiles(): CylinderTiles {
		return this.cylinderTiles
	}

	getSeedsManager(): SeedsManager {
		return this.seedsManager
	}

	/**
	 * Sets the visibility of the seeding cursor
	 * @param visible whether or not the cursor is visible
	 */
	setSeedingVisible(visible: boolean) {
		this.seedingCursor.setVisible(visible)
	}

	/**
	 * Adds some seeds to the scene, and make them available for mouse events
	 * @param seeds an array of the seeds to add to the scene
	 */
	addSeeds(seeds: Seed[]) {
		this.seedsManager.addSeeds(seeds)
	}

	animation = () => {
		requestAnimationFrame(this.animation)
		
		// compute delta time
		this.lastFrameTime = this.currentTime
		this.currentTime = performance.now()
		this.deltaTime = this.currentTime - this.lastFrameTime

		// the time relative to when the scene has started
		const time = this.currentTime - this.startTime

		// where are we in the intro ?
		const intro = Easings.cubicIn(Math.min(1, time / Config3d.TRANSITION_IN))
		
		// update the introduction sequencer
		Sequencer.update(time)

		// update the objects
		this.seedingCursor.update(time / 1000)
		
		// this.controls.update()
		this.camera.update(time, this.deltaTime)
		//this.renderer.render(this.scene, this.camera.get())
		this.noisePass.uniforms.uTime.value = (time/1000.0) % 500.0
		this.noisePass.uniforms.uIntro.value = intro
		this.composer.render()
	}

	addReferenceCylinder() {
		const geometry = new THREE.CylinderGeometry(
			Config3d.CYLINDER_RADIUS, 
			Config3d.CYLINDER_RADIUS, 
			Config3d.CYLINDER_REFERENCE_HEIGHT,
			50, 100 
		)
		const material = new THREE.MeshBasicMaterial({
			color: 0xffffff,
			transparent: true,
			depthTest: false,
			wireframe: true,
			opacity: 0.05,
		})
		const mesh = new THREE.Mesh(geometry, material)
		mesh.position.y = Config3d.CYLINDER_REFERENCE_HEIGHT * .5
		this.scene.add(mesh)
	}
}