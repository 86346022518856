import { FunctionComponent } from "react";
import styled from "styled-components";

const StCont = styled.div`
	margin-top: 2em;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`

export const FormSubmit: FunctionComponent = ({ children }) => {
	return (
		<StCont>
			{ children }
		</StCont>
	)
}