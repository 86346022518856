import axios from "axios"
import { Button } from "Components/Button"
import { SeedMemory } from "Components/SeedMemory"
import dayjs from "dayjs"
import { useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { Sizes } from "Theme/Sizes"
import useAsyncEffect from "use-async-effect"
import { Seed } from "../types/Seed"


interface IStProps {
	show: boolean
}

const StCont = styled.div<IStProps>`
	position: fixed;
	z-index: 999;
	color: white;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	transition: all 0.3s ease-out;
	opacity: ${props => props.show ? 1 : 0};

	.details-wrapper {
		display: flex;
		flex-direction: row;
		background: black;
		height: 50vh;
		z-index: 2;
		width: 100%;

		.left, .right {
			flex-basis: 50%;
			padding: 20px;
			flex-grow: 2;
		}

		.left {
			max-width: 50%;
		}

		.right {
			display: flex;
			flex-direction: column;
		}

		h2 {
			font-size: ${Sizes.bigger};
			text-align: right;
		}

		.seed-infos {
			flex-grow: 2;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			font-size: ${Sizes.small};

			button {
				margin-top: 1em;
			}

			p {
				margin: 0.2em 0;
			}
		}
	}

	.hole {
		height: min(50vh, 100vw);
		width: min(50vh, 100vw);
		z-index: 1;
		position: relative;

		.coordinates {
			position: absolute;
			bottom: 50%;
			left: 50%;
			margin-left: 15px;
			margin-bottom: 40px;
			font-size: ${Sizes.small};

			mark {
				background-color: rgba(0, 0, 0, 0.6);
			}
		}

		&::after {
			content: "";
			display: block;
			width: 90%;
			height: 90%;
			box-shadow: 0 0 0 999px black,
				0 0 10px 2px black inset;
			border-radius: 50%;
			margin-left: 5%;
			margin-top: 5%;
		}
	}

	@media (max-width: 768px) {
		.details-wrapper {
			.left, .right {
				padding: 10px;
			}
			.seed-infos {
				font-size: ${Sizes.smaller};
			}
			h2 {
				font-size: ${Sizes.small};
			}
		}
	}
`

interface IProps {
	seed: Seed
	onClose: () => void
}

/**
 * This component displays the informations of a Seed
 */
export function SeedViewer({
	seed,
	onClose,
}: IProps) {
	const [show, setShow] = useState<boolean>(false)

	useEffect(() => {
		setShow(true)
	}, [])

	const formatedDate: string = useMemo(() => dayjs(seed.createdAt).format("DD/MM/YYYY"), [])
	const formatedTime: string = useMemo(() => dayjs(seed.createdAt).format("HH:mm"), [])

	return (
		<StCont show={show}>
			<div className="details-wrapper">
				<div className="right">
					<h2>Seed {seed.id || "yet to be identified"}</h2>
					<div className="seed-infos">
						<p>planted by <strong>{seed.author}</strong></p>
						<p>the <strong>{ formatedDate }</strong> at <strong>{ formatedTime }</strong></p>
						{!seed.id ? (
							<p>this seed has not been identified by the system yet. come back in a few minutes to see its memory</p>
						):(
							seed.message || seed.hasImage) ? (
								<p>a memory was left with the seed</p>
							):(
								<p>this seed was planted without any memory</p>
							)
						}
						
						<Button theme="primary" onClick={onClose}>Continue exploration</Button>
					</div>
				</div>
				{seed.id && (seed.message || seed.hasImage) && (
					<div className="left">
						<SeedMemory seed={seed} />
					</div>
				)}
			</div>
			<div className="hole">
				<p className="coordinates"><mark>[{seed.positionX}; {seed.positionY}]</mark></p>
			</div>
		</StCont>
	)
}