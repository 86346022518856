import { Sequencer } from "Logic/Sequencer"
import { useEffect, useState } from "react"
import { Introduction1 } from "./Introduction1"
import { Introduction2 } from "./Introduction2"

export function Introductions() {
	const [showIntro1, setShowIntro1] = useState<boolean>(false)
	const [showIntro2, setShowIntro2] = useState<boolean>(false)

	useEffect(() => {
		Sequencer.subscribe((changes) => {
			for (const change of changes) {
				if (change.name === "introduction-1") {
					setShowIntro1(change.state)
				}
				else if (change.name === "introduction-2") {
					setShowIntro2(change.state)
				}
			}
		})
	}, [])

	return (
		<>
			<Introduction1 show={showIntro1} />
			<Introduction2 show={showIntro2} />
		</>
	)
}