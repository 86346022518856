/**
 * Given an object, turns it into a string for css class property. 
 * ex { abd: false, def: true, gh: true } -> outputs: "def gh "
 * @param classList an object where the keys will be a classname if its property evaluates to true
 * @returns 
 */
 export function classes(classList: { [key: string]: boolean }): string {
	let str = ""
	for (const key in classList) {
		if (classList[key]) str+= key+" "
	}
	return str
}