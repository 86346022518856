import { FunctionComponent } from "react";
import styled, { css } from "styled-components";
import { Colors } from "Theme/Colors";


interface IStProps {
	layout: "horizontal" | "vertical"
}

const StCont = styled.div<IStProps>`
	display: flex;
	flex-direction: ${props => props.layout === "vertical" ? "column" : "row"};
	margin-top: 1.5em;
	position: relative;

	label {
		margin-bottom: 0.3em;
		font-weight: 600;
	}

	.error-message {
		color: ${Colors.error};
		position: absolute;
		top: 0;
		right: 5px;
		z-index: 1;
		font-weight: bold;
		pointer-events: none;
	}

	${props => props.layout === "horizontal" && css`
		align-items: center;
	`}
`

interface Props {
	error?: string
	layout?: "horizontal" | "vertical"
}

export const FormEntry: FunctionComponent<Props> = ({ 
	error, 
	layout = "vertical",
	children 
}) => {
	return (
		<StCont layout={layout}>
			{ error && (
				<div className="error-message">
					{ error }
				</div>
			)}
			{ children }
		</StCont>
	)
}