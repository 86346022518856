import axios from "axios"
import { io, Socket } from "socket.io-client"
import { SceneManager } from "../Logic/SceneManager"
import { Seed } from "../types/Seed"
import { SocketEvents } from "../types/SocketEvents"
import { MessageTileData, MessageTileUpdate } from "../types/SocketMessages"

export function createSocketConnection(sceneManager: SceneManager): Socket {
	const socket = io(process.env.REACT_APP_API_URL as string)
	socket.on("connect", () => {
		console.log(socket.id)
	})

	/** receiving TILE data - the Tile should be created in the environment */
	socket.on(SocketEvents.TILE_DATA, async (data: MessageTileData, callback) => {
		// the tile data is on S3, get the data from the file
		const tileBuffer = await axios.get(data.url, {
			responseType: "arraybuffer"
		})

		// put the data into a buffer and draw it
		const uint8buffer = new Uint8Array(tileBuffer.data)

		// inform the server that the data was properly received
		callback({
			id: data.id,
			version: data.version
		})

		// send the data to the TileManager so that it can be processed
		const version = (data.updates && data.updates.length > 0) ? data.updates[0].version-1 : data.version
		sceneManager.getCylinderTiles().setTileRawBuffer(data.id, uint8buffer, version)

		// there might be updates aswell if so send those to the manager
		if (data.updates) {
			sceneManager.getCylinderTiles().updateTileBuffer(data.id, data.updates)
		}
	})

	/** receiving the update of some Tile data */
	socket.on(SocketEvents.TILE_UPDATE, async (updateData: MessageTileUpdate, callback) => {
		// inform the server that data was properly received
		callback({
			id: updateData.id,
			version: updateData.version
		})

		// send data to be processed
		sceneManager.getCylinderTiles().updateTileBuffer(updateData.id, updateData.updates)
	})

	/** receiving some seeds data */
	socket.on(SocketEvents.SEEDS_DATA, async (seeds: Seed[], callback) => {
		// send back the seeds received by the server
		callback(seeds.map(seed => seed.id))

		// add the seeds to the scene
		sceneManager.addSeeds(seeds)
	})

	return socket
}