import { FunctionComponent, HTMLAttributes } from "react"
import styled, { css } from "styled-components"
import { Colors } from "Theme/Colors"
import { Sizes } from "Theme/Sizes"


interface StButtonProps {
	active: boolean
}

const StButton = styled.button<StButtonProps>`
	background: ${Colors.primary};
	border: none;
	border-radius: 80px;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 60px;
	padding: 0 1em;
	cursor: pointer;
	transition: all 0.3s ease-out;
	font-size: ${Sizes.big};
	font-weight: 600;
	color: white;
	min-width: 250px;
	
	i {
		margin-right: 10px;
		font-size: 32px;
		transition: all 0.3s ease-out;
	}

	span {
		flex-grow: 2;
	}

	&:hover {
		transform: scale(1.05);
	}

	${props => props.active && css`
		background: ${Colors.error};
		
		i {
			transform: rotate(45deg);
		}
	`}

	@media (max-width: 768px) {
		font-size: ${Sizes.normal};
		min-width: auto;
		height: 42px;

		i {
			font-size: 22px;
		}
	}
`

interface ToolProps {
	title: string
	activeTitle?: string
	active?: boolean
	onToggle: (active: boolean) => void
	onTouchEnd?: () => void
}

export const Tool: FunctionComponent<ToolProps> = ({
	active = false,
	title,
	activeTitle,
	onToggle,
	onTouchEnd,
	children
}) => {
	return (
		<StButton 
			active={active}
			onClick={() => onToggle(!active)}
			onTouchEnd={onTouchEnd}
		>
			{ children } 
			<span>
				{ (active && activeTitle) ? activeTitle : title }
			</span>
		</StButton>
	)
}