import { ScrollIcon } from "Components/ScrollIcon"
import { Sequencer } from "Logic/Sequencer"
import { useEffect, useState } from "react"
import styled from "styled-components"


interface IStProps {
	show: boolean
}

const StCont = styled.div<IStProps>`
	position: absolute;
	bottom: 20px;
	left: 20px;
	background: black;
	color: white;
	transition: all 0.5s ease-out;
	display: flex;
	flex-direction: row;
	max-width: 300px;
	align-items: center;
	opacity: ${props => props.show ? 1 : 0};
	padding: 10px;
	border-radius: 6px;

	span {
		margin-left: 20px;
	}

	@media (max-width: 1023px) {
		display: none;
	}
`

export function ScrollInfos() {
	const [show, setShow] = useState<boolean>(false)

	useEffect(() => {
		Sequencer.subscribe((changes) => {
			const c = changes.find(change => change.name === "interface")
			if (c && c.state) {
				setShow(true)

				// a little bit of a cheat to add the listener on the webgl renderer
				const cvs = document.querySelector("canvas")
				cvs!.addEventListener("wheel", (event) => {
					if (Math.abs(event.deltaY) > 0) {
						setShow(false)
					}
				})
			}
		})
	}, [])

	return (
		<StCont show={show}>
			<ScrollIcon />
			<span><strong>scroll</strong> to move vertically in the space</span>
		</StCont>
	)
}