type ObservableHandler<E> = (event: E) => void

export class Observable<E> {
	observers: ObservableHandler<E>[]

	constructor() {
		this.observers = []
	}

	subscribe(handler: ObservableHandler<E>) {
		this.observers.push(handler)
	}

	unsubscribe(handler: ObservableHandler<E>) {
		this.observers = this.observers.filter(observer => observer !== handler)
	}

	fire(event: E) {
		this.observers.forEach(observer => observer(event))
	}
}