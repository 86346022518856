export const Config3d = {
	CYLINDER_RADIUS						:	40,
	CYLINDER_REFERENCE_HEIGHT	: 800,
	TILE_SEGMENTS							: 5,

	// camera settings
	CAMERA: {
		LOOK_AT_START_Y: 30,

		INTRODUCTION: {
			duration: 17000,			// ms
			positionStartY: 600
		}
	},

	TRANSITION_IN: 3000,
} 