import styled from "styled-components";

const StCont = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0px;
	background: rgba(0, 0, 0, 0.3);
	z-index: 1;
`

interface IProps {
	onClick?: () => void
}

export function Cover({ onClick }: IProps) {
	return <StCont onClick={onClick} />
}