import { IntroductionHeader } from "Components/IntroductionHeader"


interface IProps {
	show: boolean
}

export function Introduction2({ show }: IProps) {
	return (
		<IntroductionHeader
			show={show}
		>
			You can plant seeds close to the structure which has already grown. Your seed will start by growing fast, but it can take a few hours for its complete growth. Once a seed is planted, its roots will forever be a part of the structure. 
		</IntroductionHeader>
	)
}