import styled from "styled-components"
import { ReactComponent as SlideArrows } from "Assets/slide-arrows.svg"
import React, { useEffect, useRef, useState } from "react"
import { Sequencer } from "Logic/Sequencer"
import { useRecoilValue } from "recoil"
import { toolState } from "State/Tool"
import { ToolTypes } from "types/Tools"
import { Sizes } from "Theme/Sizes"


interface IStProps {
	show: boolean
}

const StCont = styled.aside<IStProps>`
	position: fixed;
	background: black;
	border-radius: 6px;
	padding: 15px;
	color: white;
	bottom: 0;
	left: 0;
	transition: all 0.5s ease-out;
	opacity: ${props => props.show ? 1 : 0};

	span {
		position: absolute;
		font-size: ${Sizes.smaller};
		width: 200px;
		transform-origin: 0 0;
		transform: rotate(-90deg);
		left: 4px;
		bottom: 32px;
		pointer-events: none;
	}

	@media (min-width: 1024px) {
		display: none;
	}
`

interface IProps {
	onScroll: (deltaY: number) => void
	hide?: boolean
}

/**
 * A component designed to handle the motion of the Camera along the Y-axis for Touch Devices
 */
export function MobileCameraScroll({ onScroll, hide }: IProps) {
	const activeTool = useRecoilValue(toolState)
	const [show, setShow] = useState<boolean>(false)
	const firstShow = useRef<boolean>(false)

	const mousePosY = useRef<number>(0)

	const onTouchStart = (event: React.TouchEvent<HTMLElement>) => {
		mousePosY.current = event.touches[0].clientY
	}
	
	const onTouchMove = (event: React.TouchEvent<HTMLElement>) => {
		const Y = event.touches[0].clientY
		const dy = Y - mousePosY.current
		mousePosY.current = Y

		if (activeTool === ToolTypes.NONE && Math.abs(dy) > 0) {
			onScroll(dy)
		}
	}

	useEffect(() => {
		Sequencer.subscribe(changes => {
			const c = changes.find(change => change.name === "interface")
			if (c && c.state) {
				setShow(true)
				firstShow.current = true
			}
		})
	}, [])

	useEffect(() => {
		setShow(firstShow.current ? !(activeTool === ToolTypes.SEEDING) : false)
	}, [activeTool])

	return (
		<StCont onTouchStart={onTouchStart} onTouchMove={onTouchMove} show={hide ? false : show}>
			<SlideArrows />
			<span>slide to move vertically</span>
		</StCont>
	)
}