import { useEffect, useRef, useState } from "react"
import { RecoilRoot } from "recoil"
import styled from "styled-components"
import { Scene3d } from "./Containers/Scene3d"
import { SeedForm } from "./Containers/SeedForm"
import { SeedViewer } from "./Containers/SeedViewer"
import { Toolbox } from "./Containers/Toolbox"
import { createSocketConnection } from "./Network/Socket"
import { SceneManager } from "./Logic/SceneManager"
import { Seed } from "./types/Seed"
import { classes } from "./Utils/Directives"
import { IVector2 } from "./Utils/Vector2d"
import { Header } from "Containers/Header"
import { Introductions } from "Containers/Intruduction/Intructions"
import { ScrollInfos } from "Containers/ScrollInfos"
import { MobileCameraScroll } from "Containers/MobileCameraScroll"


const StMain = styled.main``

function App() {
	const canvasRef = useRef<HTMLCanvasElement>(null)
	const [sceneManager, setSceneManager] = useState<SceneManager|null>(null)
	const [seedPosition, setSeedPosition] = useState<IVector2>()

	// the seed currently hovered
	const [seedHovered, setSeedHovered] = useState<Seed | null>(null)
	const [seedClicked, setSeedClicked] = useState<Seed|null>(null)
	
	useEffect(() => {
		// setup the Tiles Manager
		const sManager = new SceneManager(canvasRef.current!)
		setSceneManager(sManager)
		createSocketConnection(sManager)
	}, []) 

	const onAddSeed = (x: number, y: number) => {
		setSeedPosition({ x, y })
	}

	const onAddSeedClose = () => {
		setSeedPosition(undefined)
	}

	const onSeedClicked = (seed: Seed) => {
		if (!sceneManager) return

		// the camera should be moved on the seed
		const coords = sceneManager.getCylinderTiles().tile2dToWorldCoordinates(seed.positionX, seed.positionY)
		sceneManager.camera.setSeedViewer(true, coords.setY(coords.y + 13))		

		setSeedClicked(seed)
		setSeedHovered(null)
	}

	const closeSeedViewer = () => {
		sceneManager!.camera.setSeedViewer(false)
		setSeedClicked(null)
		setSeedHovered(null)
	}

	const onMobileScroll = (deltaY: number) => {
		if (!sceneManager) return
		sceneManager.camera.wheelVelocity-= deltaY
	}

  return (
		<RecoilRoot>
			<StMain className={classes({
				"cursor-pointer": !!seedHovered
			})}>
				<Scene3d 
					ref={canvasRef}
					sceneManager={sceneManager}
					onAddSeed={onAddSeed} 
					addingSeedPosition={seedPosition}
					onSeedClicked={onSeedClicked}
					seedHovered={seedHovered}
					onSeedHovered={setSeedHovered}
				/>
				<Header />
				{ seedPosition && <SeedForm position={seedPosition} onClose={onAddSeedClose} onWatchSeed={onSeedClicked} /> }
				{ seedClicked && <SeedViewer seed={seedClicked} onClose={closeSeedViewer} /> }

				<ScrollInfos />
				<MobileCameraScroll onScroll={onMobileScroll} hide={!!seedClicked} />

				<Toolbox hide={!!seedClicked} />

				<Introductions />
			</StMain>
		</RecoilRoot>
  );
}

export default App;
