import { Vector3 } from "three"

export const Colors = {
	primary: 		"#52E39E",
	error: 			"#DB5B72",
	primaryV3:	new Vector3(0.322, 0.89, 0.62),
	errorV3:		new Vector3(0.859, 0.357, 0.447),

	greenV3: new Vector3(0, 1, 0),
	redV3: new Vector3(1, 0, 0),
}