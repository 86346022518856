import * as THREE from "three"
import { clamp, lerp } from "three/src/math/MathUtils"
import { Config3d } from "Config/3d"
import { Observable } from "Utils/Observable"
import { Easings } from "Utils/Easings"
import { IVector2 } from "Utils/Vector2d"
import { getIndexOfSmallest } from "Utils/Arrays"


enum CameraMode {
	INTRODUCTION 		= "INTRODUCTION",
	FREE 						= "FREE",
	SEEDING 				= "SEEDING",
	SEED_VIEWER			= "SEED_VIEWER"
}

/**
 * There are 3 modes for the camera position:
 * - regular mode: 
 * 		- the camera looks at a point somewhere on the Y-axis, within the cylinder 
 * 		- the camera is able to rotate on θ arround the point, by a limited angle
 * 		- the camera is able to rotate freely on α 
 * 		- the camera is at a fixed distance D from the look-at point
 * - introduction mode:
 * 		- same as the regular mode, but the camera cannot rotate on any (θ, α) degree of rotation
 * 		- the distance from the Y-axis is 0
 * 		- the camera moves along the Y-axis, towards the look-at point
 * - seeding mode:
 * 		- the camera looses the degree of rotation θ, it can only rotate "horizontally"
 * 		- the distance D from the lookat point gets smaller, closer to 0
 */
export class CameraManager {
	object: THREE.PerspectiveCamera
	canvas: HTMLCanvasElement

	// ! position variables
	// the current mode of the camera
	mode: CameraMode
	// rotation angle arround the Y axis
	alpha: number
	// rotation angle arround the X axis
	theta: number
	// the angle of rotation of the camera towards its view vector
	tornAngle: number
	// the distance to the look at point
	distanceLookAt: number
	// the distance added to the Y of the camera
	distancePlaneHor: number
	// the position of the look-at point, which is on the Y axis
	lookAtPoint: THREE.Vector3
	// the velocity of the alpha and theta angles 
	anglesVelocity: THREE.Vector2 = new THREE.Vector2()
	// the velocity of the scroll on Y
	wheelVelocity: number = 0
	// allow for alpha angle to be adjusted when on seeding mode
	alphaAdjust: number = 0
	yAdjust: number = 0
	
	// position of the camera, is used to avoid re-allocation
	position: THREE.Vector3 = new THREE.Vector3()

	// sequence controls
	seqIntroductionTime: number

	// mouse state tracking
	mouseDown: boolean = false
	// touch tracking
	lastTouchPos: IVector2 = { x: 0, y : 0 }
	
	// the target when going into seeding mode
	targetSeeding: THREE.Vector3 = new THREE.Vector3()
	// the alpha target when going into seed viewer mode
	targetSeedViewerAlpha: number = 0

	seedingMotionObservable: Observable<null> = new Observable<null>()


	constructor(canvas: HTMLCanvasElement) {
		this.canvas = canvas
		this.object = new THREE.PerspectiveCamera(90, canvas.width / canvas.height, 0.01, 1000)
		
		this.alpha = 0
		this.theta = Math.PI * .5
		this.tornAngle = 0
		this.distanceLookAt = 0
		this.distancePlaneHor = 400
		this.lookAtPoint = new THREE.Vector3(0, Config3d.CAMERA.LOOK_AT_START_Y, 0)

		this.mode = CameraMode.INTRODUCTION
		this.seqIntroductionTime = 0

		// attach events to the canvas to get mouse delta motion
		this.attachMouseEvents()
	}

	/** returns the threejs camera object */
	get(): THREE.PerspectiveCamera {
		return this.object
	}

	/** returns true if camera is out of introduction mode */
	isReady(): boolean {
		return this.mode !== CameraMode.INTRODUCTION
	}

	/** attach the mouse events to the canvas to get the delta motion of the mouse */
	attachMouseEvents() {
		this.canvas.addEventListener("mousedown", () => this.mouseDown = true)
		this.canvas.addEventListener("mouseup", () => this.mouseDown = false)
		this.canvas.addEventListener("mouseleave", () => {
			this.mouseDown = false
			if (this.mode === CameraMode.SEEDING) {
				this.wheelVelocity = 0
				this.anglesVelocity.multiplyScalar(0)
			}
		})
		this.canvas.addEventListener("mousemove", this.onMouseMove)
		this.canvas.addEventListener("wheel", this.onMouseWheel)

		// mobile events
		this.canvas.addEventListener("touchstart", (event) => {
			this.lastTouchPos = { 
				x: event.touches[0].clientX,
				y: event.touches[0].clientY
			}
		})
		this.canvas.addEventListener("touchmove", this.onTouchMove)
	}

	onMouseMove = (event: MouseEvent) => {
		if (this.mode === CameraMode.FREE && this.mouseDown) {
			this.anglesVelocity.x-= event.movementX
			this.anglesVelocity.y-= event.movementY
		}
		if (this.mode === CameraMode.SEEDING) {
			// only move if close to the borders
			const dx = (event.clientX / window.innerWidth) - 0.5
			const dy = (event.clientY / window.innerHeight) - 0.5
			const adx = Math.abs(dx)
			const ady = Math.abs(dy)

			if (adx > 0.3) {
				this.anglesVelocity.x = -((adx-0.3)/0.2) * Math.sign(dx)
			}
			else {
				this.anglesVelocity.x = 0
			}
			if (ady > 0.3) {
				this.wheelVelocity = -((ady-0.3)/0.2) * Math.sign(dy)
			}
			else {
				this.wheelVelocity = 0
			}
		}
	}

	onTouchMove = (event: TouchEvent) => {
		if (this.mode === CameraMode.FREE) {
			const current: IVector2 = {
				x: event.touches[0].clientX,
				y: event.touches[0].clientY,
			}
			this.anglesVelocity.x-= (current.x - this.lastTouchPos.x)
			this.anglesVelocity.y-= (current.y - this.lastTouchPos.y)
			this.lastTouchPos = current
		}
		if (this.mode === CameraMode.SEEDING) {

		}
	}

	onMouseWheel = (event: WheelEvent) => {
		if (this.mode === CameraMode.FREE) {
			this.wheelVelocity+= event.deltaY
		}
	}

	onWindowResize = () => {
		this.object.aspect = window.innerWidth / window.innerHeight
		this.object.updateProjectionMatrix()
	}

	toggleSeedingMode = (forceTo?: boolean, target?: THREE.Vector3|null) => {
		if (this.isReady()) {
			if (forceTo === undefined) {
				this.mode = this.mode === CameraMode.SEEDING ? CameraMode.FREE : CameraMode.SEEDING
			}
			else {
				this.targetSeeding = target || this.lookAtPoint
				this.mode = forceTo ? CameraMode.SEEDING : CameraMode.FREE
			}
			this.anglesVelocity.set(0, 0)
			this.wheelVelocity = 0
		}
	}

	setSeedViewer = (to: boolean, target?: THREE.Vector3) => {
		if (this.isReady()) {
			if (to) {
				this.alpha = this.alpha % (2 * Math.PI)
				this.targetSeeding = target!
				this.mode = CameraMode.SEED_VIEWER
				this.anglesVelocity.set(0, 0)
				this.wheelVelocity = 0
				const ta = [
					-Math.atan2(target!.z, target!.x) - Math.PI/2,
					-Math.atan2(target!.z, target!.x) - Math.PI/2 + 2*Math.PI,
					-Math.atan2(target!.z, target!.x) - Math.PI/2 - 2*Math.PI,
				]

				// pick the closest from alpha, so that the camera motion doesn't go arround the whole scene
				const dta = ta.map(ta => Math.abs(this.alpha - ta))
				this.targetSeedViewerAlpha = ta[getIndexOfSmallest(dta)]
			}
			else {
				this.mode = CameraMode.FREE
			}
		}
	}
	
	update(time: number, deltaT: number) {
		// update the camera settings based on the mode
		if (this.mode === CameraMode.INTRODUCTION) {
			this.seqIntroductionTime+= deltaT
			const introProgress = Easings.quadInOut(Math.min(1, this.seqIntroductionTime / Config3d.CAMERA.INTRODUCTION.duration))
			const introProgress2 = Easings.quadInOut((Math.max(0.7, introProgress) - 0.7) / 0.3)
	
			// get the Y coordinates based on the progress of the introduction
			this.distanceLookAt = lerp(0, 30, introProgress2)
			this.distancePlaneHor = lerp(Config3d.CAMERA.INTRODUCTION.positionStartY, 0, introProgress)

			// rotation 
			this.tornAngle = introProgress * 2 * Math.PI

			// change the mode
			if (introProgress >= 1) {
				this.mode = CameraMode.FREE
			}
		}
		else if (this.mode === CameraMode.FREE) {
			// reset the FOV out of seeding mode
			this.object.fov = lerp(this.object.fov, 90, 0.2)

			this.lookAtPoint.y+= this.wheelVelocity * 0.01
			this.lookAtPoint.y = clamp(this.lookAtPoint.y, 3, 600)
			this.wheelVelocity*= 0.95

			this.alpha+= this.anglesVelocity.x * 0.00025
			this.theta+= this.anglesVelocity.y * 0.00025

			let thetaMax = Math.PI - 0.01
			// we need to prevent the camera from going under the cylinder
			if (this.distanceLookAt > this.lookAtPoint.y) {
				thetaMax = Math.PI - Math.acos((this.lookAtPoint.y-3) / (this.distanceLookAt-3))
			}

			this.theta = clamp(this.theta, 0.01, thetaMax)
			this.anglesVelocity.multiplyScalar(0.95)
		}
		else if (this.mode === CameraMode.SEEDING) {
			// transition
			this.lookAtPoint.y = lerp(this.lookAtPoint.y, this.targetSeeding.y, 0.2)
			this.theta = lerp(this.theta, Math.PI*.5, 0.2)
			this.object.fov = lerp(this.object.fov, 45, 0.2)

			// update position
			this.alpha+= this.anglesVelocity.x * 0.02
			this.targetSeeding.y+= this.wheelVelocity * 0.5
			
			// adjustments designed for Touch Devices
			this.alpha+= this.alphaAdjust
			this.targetSeeding.y+= this.yAdjust
			this.alphaAdjust*= 0.8
			this.yAdjust*= 0.8

			// constraint the camera Y
			this.targetSeeding.y = clamp(this.targetSeeding.y, 3, 600)
			
			this.seedingMotionObservable.fire(null)
		}
		else if (this.mode === CameraMode.SEED_VIEWER) {
			// transtion
			this.lookAtPoint.y = lerp(this.lookAtPoint.y, this.targetSeeding.y, 0.1)
			this.theta = lerp(this.theta, Math.PI*.5, 0.1)
			this.object.fov = lerp(this.object.fov, 45, 0.1)
			this.alpha = lerp(this.alpha, this.targetSeedViewerAlpha, 0.1)
		}

		//this.alpha%= 2 * Math.PI

		// update camera position based on the settings
		this.position.setFromSphericalCoords(this.distanceLookAt, this.theta, this.alpha)
		this.position.y+= this.distancePlaneHor
		this.position.add(this.lookAtPoint)
		this.object.position.copy(this.position)
		this.object.lookAt(this.lookAtPoint)
		this.object.rotateZ(this.tornAngle)
		this.object.updateProjectionMatrix()
	}
}