import * as THREE from "three"
import { Config3d } from "Config/3d"
import DawConfig from "Config/daw"
// @ts-ignore
import basicVS from "Logic/Shaders/basic.vert.glsl"
// @ts-ignore
import seedingCursorFS from "Logic/Shaders/seeding-cursor.frag.glsl"


export class SeedingCursor {
	material: THREE.ShaderMaterial
	mesh: THREE.Mesh

	constructor() {
		// ! compute the radius on the cursor
		// the height of a Tile in the 3D space
		const tileHeight3D = (2 * Math.PI * Config3d.CYLINDER_RADIUS) / DawConfig.TILES_PER_ROW
		const worldRatio = DawConfig.TILE_SIZE / tileHeight3D
		const radius = DawConfig.SEED_NEIGHBOORING_RADIUS / worldRatio

		const geometry = new THREE.PlaneGeometry(radius*2, radius*2, 1, 1)
		this.material = new THREE.ShaderMaterial({
			vertexShader: basicVS,
			fragmentShader: seedingCursorFS,
			side: THREE.DoubleSide,
			transparent: true,
			uniforms: {
				uColor: { value: new THREE.Vector4(1, 0, 0, 1) },
				uRadius: { value: radius },
				uTime: { value: 0 },
			}
		})
		this.mesh = new THREE.Mesh(geometry, this.material)
		this.setVisible(false)
	}

	getMesh(): THREE.Mesh {
		return this.mesh
	}

	updatePosition(position: THREE.Vector3) {
		// the cursor needs to be offseted a little bit
		const xz = new THREE.Vector2(position.x, position.z)
		const d = xz.length()
		const a = xz.angle()
		const pos = new THREE.Vector3(
			Math.cos(a) * (d - 1),
			position.y,
			Math.sin(a) * (d - 1)
		)

		this.mesh.position.copy(pos)
		this.mesh.rotation.y = -a + Math.PI*.5
	}

	updateColor(color: THREE.Vector3) {
		this.material.uniforms.uColor.value = new THREE.Vector4(color.x, color.y, color.z, 1.0)
		this.material.needsUpdate = true
	}

	/**
	 * Sets the visibility of the cursor
	 * @param visible if the cursor should be visible or not
	 */
	setVisible(visible: boolean) {
		this.mesh.visible = visible
	}

	update(time: number) {
		this.material.uniforms.uTime.value = time % 50000
		this.material.needsUpdate = true
	}
}