import { FunctionComponent } from "react";
import styled from "styled-components";
import { Sizes } from "Theme/Sizes";


interface IStCont {
	show: boolean
}

const StCont = styled.div<IStCont>`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
	max-width: 600px;
	color: white;
	text-align: center;
	transition: all 0.7s ease-out;
	opacity: ${props => props.show ? 1 : 0};

	h1, p {
		padding: 5px 10px;
		background-color: rgba(0, 0, 0, 0.8);
		display: inline-block;
		line-height: 1.7em;
	}

	@media (max-width: 768px) {
		width: 100%;

		h1 {
			font-size: ${Sizes.normal};
		}
	}
`

interface IProps {
	title?: string
	show: boolean
}

export const IntroductionHeader: FunctionComponent<IProps> = ({
	title,
	show,
	children,
}) => {
	return (
		<StCont show={show}>
			{title && <h1>{ title }</h1>}
			<p>{ children }</p>
		</StCont>
	)
}