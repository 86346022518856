export class Vector2d {
	x: number
	y: number

	constructor(x: number, y: number) {
		this.x = x
		this.y = y
	}
}

export interface IVector2 {
	x: number
	y: number
}